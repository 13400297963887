import React, { useState } from "react";
import { GoogleLogin } from 'react-google-login';
import logo from "../assets/logo.png";
import firebase from 'firebase'
import _ from "lodash";
import moment from "moment-mini";

const Login = (props) => {
    const [inputCode, setInputCode] = useState(false)
    const [profile, setProfile] = useState({})
    const [code, setCode] = useState('')

    const responseGoogle = (response) => {
        if (response?.profileObj && Object.keys(response?.profileObj).length > 0) {
            setProfile(response?.profileObj)
            checkCode(response?.profileObj)
            localStorage.setItem('isLoggedIn', true)
        }
    }

    const checkCode = (profileObj) => {
        let db = firebase.firestore()
        let docRef = db.collection("User_KinhDich").doc(profileObj?.email);
        let now = moment()

        // chỗ này kiểm tra email user đã login bao giờ chưa ?
        docRef.get().then((doc) => {
            if (doc.exists) { // đã từng login rồi
                let exprireDate = doc.data() && doc.data()?.exprireDate && doc.data()?.exprireDate.toDate();
                let code = doc.data() && doc.data()?.code;
                
                // TH user đó đã từng login rồi nhưng chưa từng nhập code và hết hạn sử dụng free (7 ngày từ ngày đầu tiên login) => bắt nhập code + ko cho vô web
                if (exprireDate && moment(exprireDate).valueOf() <= now.valueOf() && !code) {
                    setInputCode(true)
                } else { // TH user đó đã từng login rồi nhưng có code hoặc còn hạn sử dụng free => cho vô web
                    // lưu local thông tin user login để hiển thị nội dung web
                    if (!code && exprireDate) {
                        profileObj.exprireDate = exprireDate;
                    }
                    props.getProfile(profileObj || {})
                    localStorage.setItem("profile", profileObj && JSON.stringify(profileObj))
                }
            } else { // chưa từng login => cho vô web (báo đc sử dụng free 7 ngày từ ngày login + lưu thông tin user vào firestore)
                // thêm email user vào cloud firestore
                db.collection("User_KinhDich").doc(profileObj?.email).set({
                    activeSearch: true,
                    email: profileObj?.email,
                    exprireDate: now.add(7, 'days').toDate(),
                    isActive: true,
                    loginDate: now.toDate(),
                    name: profileObj?.name,
                })
                    .then(() => {
                        profileObj.exprireDate = now.add(7, 'days').toDate();
                        props.getProfile(profileObj || {})
                        localStorage.setItem("profile", profileObj && JSON.stringify(profileObj))
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
            }

        }).catch((error) => {
            console.error("Error getting document: ", error);
        });
    }

    const sendCode = () => {
        let db = firebase.firestore()
        if (code) {
            let redeem_code = db.collection("redeem_code").doc("Ac2eWZZm0iE0N5fc5WBQ")

            redeem_code.get().then((doc) => {
                let arr = doc.data();

                // chỗ này kiểm tra code user nhập đã từng đc sử dụng chưa ?
                if (arr?.[code] && arr?.[code] == true) { // code chưa đc sử dụng
                    // lưu local thông tin user login để hiển thị nội dung web
                    delete profile.exprireDate
                    props.getProfile(profile || {})
                    localStorage.setItem("profile", profile && JSON.stringify(profile))

                    // update isActive = false để không cần nhập code lần nữa
                    redeem_code.set({
                        [code]: false
                    }, { merge: true });

                    // update ngày hết hạn của user là 100 năm từ ngày hôm nay và lưu code vào thông tin user
                    db.collection("User_KinhDich").doc(profile?.email).set({
                        exprireDate: moment().add(100, 'years').toDate(),
                        code: code
                    }, { merge: true });
                } else { // code đã đc user khác sử dụng rồi
                    alert("Code không hợp lệ. Vui lòng nhập code khác!")
                }
            });
        }
    }

    return (
        <div className="div-login">
            <img src={logo} className="header-logo mb-3" />
            {!inputCode ?
                <>
                    <h4 className="font-weight-bold mb-3">Vui lòng đăng nhập để nhìn thấy thông tin website</h4>
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_ID}
                        buttonText="Đăng nhập bằng Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={false}
                    />
                </>
                :
                <>
                    <p className="mb-3 font-weight-bold">Vui lòng nhập code và nhấn nút "Gửi code" để có thể sử dụng website</p>
                    <input placeholder="Code" className="px-1 mr-2" value={code} onChange={e => setCode(e.target.value)} />
                    <button onClick={e => sendCode(e.target.value)} className="btn-blue" disabled={code?.length == 0} style={{ cursor: code?.length == 0 ? `not-allowed` : `pointer` }}>Gửi code</button>
                </>
            }
        </div>
    )
}

export default Login