import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ListQueKinhDich from "../common/QueKinhDich";
import { getCanChiOfDay, getLucThu, getThanSat } from "../utils";

const LucHao = (props) => {
    const [visible, setVisible] = useState(false)
    const [lucThanList, setLucThanList] = useState([]);
    const [chiList, setChiList] = useState([]);
    const [chiListQuaiBien, setChiListQuaiBien] = useState([]);
    const [thanSatList, setThanSatList] = useState([]);
    const [lucThuList, setLucThuList] = useState([]);
    const [batThuanQuaiList, setBatThuanQuaiList] = useState([]);
    const [indexHao, setIndexHao] = useState(false)

    useEffect(() => {
        let lucThanList = [];
        let chiList = [];
        let chiListQuaiBien = [];
        let thanSatList = [];
        let batThuanQuaiList = [];
        let arrQuaiGoc = _.split(props?.queKinhDich?.CodeQuai, '-', 6) || [];
        let arrQuaiBien = _.split(props?.queHaoDong?.CodeQuai, '-', 6) || [];

        // bát thuần quái lấy từ ds quẻ kinh dịch có huyenkhongnguhanh bằng huyenkhongnguhanh của quẻ kinh dịch và huyenkhongquaivan = 1
        let objBatThuanQuai = _.find(ListQueKinhDich, function (o) {
            if (o?.huyenkhongnguhanh == props?.queKinhDich?.huyenkhongnguhanh && o?.huyenkhongquaivan == 1) {
                return o;
            }
        }) || {}

        // lục thân thì lấy diachiquai lẻ, ngược lại chi thì lấy diachiquai chẵn, bát thuần quái lấy lục thân của objBatThuanQuai
        for (let i = 1; i <= 12; i++) {
            if (i % 2 !== 0) {
                lucThanList.push(props?.queKinhDich?.[`diachiquai${i}`])
                batThuanQuaiList.push(objBatThuanQuai?.[`diachiquai${i}`])
            } else {
                chiList.push(props?.queKinhDich?.[`diachiquai${i}`])
                chiListQuaiBien.push(props?.queHaoDong?.[`diachiquai${i}`])

                let thanSatName = getThanSat(props?.ngayCanChi?.can, props?.ngayCanChi?.chi, props?.queKinhDich?.[`diachiquai${i}`])
                thanSatList.push(thanSatName?.length > 0 ? thanSatName : '-')

                setThanSatList(thanSatList)
            }
        }

        let canChiOfDay = getCanChiOfDay();
        let lucThu = getLucThu(canChiOfDay);

        // for (let i = 0; i < arrQuaiGoc?.length; i++) {
        //     if (arrQuaiGoc[i] === 'Lien' && (arrQuaiBien[i] === 'Lien' || !arrQuaiBien[i])) {
        //         hoaChuLamList.push("/")
        //     }
        //     if (arrQuaiGoc[i] === 'Dut' && (arrQuaiBien[i] === 'Dut' || !arrQuaiBien[i])) {
        //         hoaChuLamList.push("//")
        //     }
        //     if (arrQuaiGoc[i] === 'Dut' && arrQuaiBien[i] === 'Lien') {
        //         hoaChuLamList.push("x")
        //     }
        //     if (arrQuaiGoc[i] === 'Lien' && arrQuaiBien[i] === 'Dut') {
        //         hoaChuLamList.push("o")
        //     }
        // }

        setLucThuList(lucThu?.length > 0 ? _.reverse(lucThu) : [])
        setLucThanList(lucThanList)
        setChiList(chiList)
        setChiListQuaiBien(chiListQuaiBien)
        setBatThuanQuaiList(batThuanQuaiList)
    }, [props.queKinhDich, props.queHaoDong])

    const onMoveDetailDiv = () => {
        setVisible(!visible)
    }

    const showKVAndNP = (chiQuaiGoc) => {
        let text = ''
        if (props?.khongVong?.indexOf(chiQuaiGoc) != -1) {
            text = text + ' - KV'
        }
        if (props?.nguyetPha?.indexOf(chiQuaiGoc) != -1) {
            text = text + ' - NP'
        }

        return text
    }

    const reRenderQuaiGoc = (index, key) => {
        let arrQuaiGoc = _.split(props?.queKinhDich?.CodeQuai, '-', 6);
        let arrQuaiBien = props?.queHaoDong?.CodeQuai?.length > 0 ? _.split(props?.queHaoDong?.CodeQuai, '-', 6) : _.split(props?.queKinhDich?.CodeQuai, '-', 6);

        if (key === 'duong dong') { // status 1
            arrQuaiGoc[index] = 'Lien'
            arrQuaiBien[index] = 'Dut'
        }

        if (key === 'am') { // status 2
            arrQuaiGoc[index] = 'Dut'
            arrQuaiBien[index] = 'Dut'
        }

        if (key === 'duong') { // status 3
            arrQuaiGoc[index] = 'Lien'
            arrQuaiBien[index] = 'Lien'
        }

        if (key === 'am dong') { // status 4
            arrQuaiGoc[index] = 'Dut'
            arrQuaiBien[index] = 'Lien'
        }

        // quai goc thay doi, nhung quai bien khong thay doi
        // arrQuaiBien[index] = arrQuaiGoc[index] === 'Lien' ? 'Dut' : 'Lien';
        // arrQuaiGoc[index] = arrQuaiGoc[index] === 'Lien' ? 'Dut' : 'Lien';

        let newArrQuaiGoc = arrQuaiGoc.join("-")
        let objQuaiGoc = _.find(ListQueKinhDich, ["CodeQuai", newArrQuaiGoc]) || {}
        props.getQueKinhDich(objQuaiGoc)

        let newArrQuaiBien = arrQuaiBien.join("-")
        let objQuaiBien = _.find(ListQueKinhDich, ["CodeQuai", newArrQuaiBien]) || {}
        props.getQueHaoDong(newArrQuaiGoc == newArrQuaiBien ? {} : objQuaiBien)
    }

    const renderTagP = (arr, key) => {
        return arr.map(item => {
            return <p className="mb-3">{item} {key == 'showKV-NP' ? showKVAndNP(item) : ''}</p>
        })
    }

    const renderQuaiGoc = () => {
        let arr = _.split(props?.queKinhDich?.CodeQuai, '-', 6) || [];
        return arr.map((item, index) => {
            if (item === 'Dut') {
                return (
                    <div className="d-flex justify-content-between mb-2 pointer"
                        style={{ position: "relative" }}
                        onClick={() => {
                            let modal = document.getElementById(`myModal-${index}`); // div moi click vo
                            let state = document.getElementById(`myModal-${indexHao}`); // div vua click truoc do
                            let display = modal.style.display;
                            modal.style.display = display == "block" ? "none" : "block";
                            if (state) {
                                state.style.display = "none"
                            }
                            setIndexHao(index == indexHao ? null : index)
                        }}
                    >
                        <div className="div-que-do" style={{ width: "40%" }}>{props?.queKinhDich?.[`note${index + 1}`] ? props?.queKinhDich?.[`note${index + 1}`] : ''}</div>
                        <div className="div-que-do" style={{ width: "40%" }} />

                        <div id={`myModal-${index}`} className="div-select-hao">
                            <FontAwesomeIcon className="icon-select-hao" icon={faCaretLeft} />
                            <p className="font-weight-bold" style={{ borderBottom: `1px solid #fff` }}>Chọn Loại Hào</p>
                            <p className="mb-1 pointer" onClick={() => reRenderQuaiGoc(index, 'am')}>Âm</p>
                            <p className="mb-1 pointer" onClick={() => reRenderQuaiGoc(index, 'duong')}>Dương</p>
                            <p className="mb-1 pointer" onClick={() => reRenderQuaiGoc(index, 'am dong')}>Âm Động</p>
                            <p className="mb-1 pointer" onClick={() => reRenderQuaiGoc(index, 'duong dong')}>Dương Động</p>
                        </div>
                    </div>
                )
            }

            if (item === 'Lien') {
                return (
                    <>
                        <div className="div-que-do mb-2 w-100 pointer"
                            style={{ position: "relative" }}
                            onClick={() => {
                                let modal = document.getElementById(`myModal-${index}`); // div moi click vo
                                let state = document.getElementById(`myModal-${indexHao}`); // div vua click truoc do
                                let display = modal.style.display;
                                modal.style.display = display == "block" ? "none" : "block";
                                if (state) {
                                    state.style.display = "none"
                                }
                                setIndexHao(index == indexHao ? null : index)
                            }}
                        >
                            {props?.queKinhDich?.[`note${index + 1}`] ? props?.queKinhDich?.[`note${index + 1}`] : ''}
                            <div id={`myModal-${index}`} className="div-select-hao">
                                <FontAwesomeIcon className="icon-select-hao" icon={faCaretLeft} />
                                <p className="font-weight-bold" style={{ borderBottom: `1px solid #fff` }}>Chọn Loại Hào</p>
                                <p className="mb-1 pointer" style={{ margin: "0 -15px", padding: "0 15px" }} onClick={() => reRenderQuaiGoc(index, 'am')}>Âm</p>
                                <p className="mb-1 pointer" style={{ margin: "0 -15px", padding: "0 15px" }} onClick={() => reRenderQuaiGoc(index, 'duong')}>Dương</p>
                                <p className="mb-1 pointer" style={{ margin: "0 -15px", padding: "0 15px" }} onClick={() => reRenderQuaiGoc(index, 'am dong')}>Âm Động</p>
                                <p className="mb-1 pointer" style={{ margin: "0 -15px", padding: "0 15px" }} onClick={() => reRenderQuaiGoc(index, 'duong dong')}>Dương Động</p>
                            </div>
                        </div>
                    </>
                )
            }
        })
    }

    const renderQuaiBien = () => {
        let arrHaoDong = _.split(props?.queHaoDong?.CodeQuai, '-', 6) || [];
        let arr = _.split(props?.queKinhDich?.CodeQuai, '-', 6) || [];

        return arrHaoDong.map((item, index) => {
            let className = item == arr[index] ? "div-que-do-nhat" : "div-que-do"
            if (item === 'Dut') {
                return (
                    <div className="d-flex justify-content-between mb-2">
                        <div className={className} style={{ width: "40%" }} />
                        <div className={className} style={{ width: "40%" }} />
                    </div>
                )
            }

            if (item === 'Lien') {
                return <div className={`${className} mb-2`} style={{ width: "100%" }} />
            }
        })
    }

    return (
        <div className="div-luc-hao">
            <div className={`d-flex ${visible ? `justify-content-between` : ``}`}>
                <div className="d-flex flex-fill text-center align-items-end pb-3">
                    <div className={`div-detail-left ${!visible && `mr-1`}`} />
                    {visible &&
                        <div className="div-detail-center w-100 d-flex m-0 py-2">
                            <div className="div-border-right px-1 flex-fill mx-0">
                                <p className="font-weight-bold mb-3">Tên Hào</p>
                                <p className="mb-3">Hào 6 (Thượng)</p>
                                <p className="mb-3">Hào 5 (Ngũ)</p>
                                <p className="mb-3">Hào 4 (Tứ)</p>
                                <p className="mb-3">Hào 3 (Tam)</p>
                                <p className="mb-3">Hào 2 (Nhị)</p>
                                <p className="mb-3">Hào 1 (Sơ)</p>
                            </div>
                            <div className="div-border-right px-1 flex-fill mx-0">
                                <p className="font-weight-bold mb-3">Bát Thuần Quái</p>
                                {renderTagP(batThuanQuaiList)}
                            </div>
                            <div className="div-border-right flex-fill mx-0 px-1">
                                <p className="font-weight-bold mb-3">Thần Sát</p>
                                {renderTagP(thanSatList)}
                            </div>
                            <div className="px-0 flex-fill mx-0">
                                <p className="font-weight-bold mb-3">Lục Thần</p>
                                {renderTagP(lucThuList)}
                            </div>
                        </div>
                    }
                    <div className="div-detail-right" onClick={onMoveDetailDiv}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                        </svg>
                    </div>
                </div>

                <div className="d-flex flex-fill justify-content-between align-items-end" style={visible ? { marginRight: 0 } : { marginRight: "25%" }}>
                    <div className="flex-fill pb-4 text-center">
                        <p className="font-weight-bold mb-3">Lục Thân</p>
                        {renderTagP(lucThanList)}
                    </div>
                    <div className="flex-fill" style={{ width: "200px" }}>
                        <div className="flex-column align-items-start" style={{ height: "93px" }}>
                            {props?.queHaoDong && Object.keys(props?.queHaoDong)?.length > 0 && <div className="font-weight-bold text-center mb-2">Quái Gốc</div>}
                            <div className="header-text-orange text-center mb-2">{props?.queKinhDich?.tenque}</div>
                        </div>
                        <p className="header-text-orange text-center mb-3">{props?.queKinhDich?.huyenkhongnguhanh}</p>
                        {renderQuaiGoc()}
                        <p className="header-text-orange text-center mb-2">{props?.queKinhDich?.huyenkhongquaivan}</p>
                    </div>
                    <div className="px-1 flex-fill pb-4 text-left">
                        <p className="font-weight-bold mb-3">Chi</p>
                        {renderTagP(chiList, 'showKV-NP')}
                    </div>

                    {props?.queHaoDong && Object.keys(props?.queHaoDong)?.length > 0 && // props?.queHaoDong?.CodeQuaiGocPrototype == props?.queKinhDich?.CodeQuai &&
                        <>
                            <div className="flex-fill" style={{ width: "200px" }}>
                                <div className="flex-column align-items-start" style={{ height: "93px" }}>
                                    <div className="font-weight-bold text-center mb-2">Quái Biến</div>
                                    <div className="header-text-orange text-center mb-2">{props?.queHaoDong?.tenque}</div>
                                </div>
                                <p className="header-text-orange text-center mb-3">{props?.queHaoDong?.huyenkhongnguhanh}</p>
                                {renderQuaiBien()}
                                <p className="header-text-orange text-center mb-2">{props?.queHaoDong?.huyenkhongquaivan}</p>
                            </div>
                            <div className="px-1 flex-fill mb-4 text-left">
                                <p className="font-weight-bold mb-3">Chi</p>
                                {renderTagP(chiListQuaiBien)}
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default LucHao