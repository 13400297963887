import { CON_GIAP } from "../constants/constants"

const ThangCanChi = [
    { no: 1, nameCanChi: CON_GIAP.TYS},
    { no: 2, nameCanChi: CON_GIAP.SUU},
    { no: 3, nameCanChi: CON_GIAP.DAN},
    { no: 4, nameCanChi: CON_GIAP.MAO},
    { no: 5, nameCanChi: CON_GIAP.THIN},
    { no: 6, nameCanChi: CON_GIAP.TYJ},
    { no: 7, nameCanChi: CON_GIAP.NGO},
    { no: 8, nameCanChi: CON_GIAP.MUI},
    { no: 9, nameCanChi: CON_GIAP.THAN},
    { no: 10, nameCanChi: CON_GIAP.DAU},
    { no: 11, nameCanChi: CON_GIAP.TUAT},
    { no: 12, nameCanChi: CON_GIAP.HOI},
]

export default ThangCanChi