import { CON_GIAP } from "../constants/constants"

const ListKhongVong = [
    {
        listConGiap: [
            CON_GIAP.TUAT,
            CON_GIAP.HOI
        ], 
        listCanChi: [
            "GIAP_TY",
            "AT_SUU",
            "BINH_DAN",
            "DINH_MAO",
            "MAU_THIN",
            "KY_TY",
            "CANH_NGO",
            "TAN_MUI",
            "NHAM_THAN",
            "QUY_DAU"
        ]
    },
    {
        listConGiap: [
            CON_GIAP.THAN,
            CON_GIAP.DAU
        ], 
        listCanChi: [
            "GIAP_TUAT",
            "AT_HOI",
            "BINH_TY",
            "DINH_SUU",
            "MAU_DAN",
            "KY_MAO",
            "CANH_THIN",
            "TAN_TY",
            "NHAM_NGO",
            "QUY_MUI"
        ]
    },
    {
        listConGiap: [
            CON_GIAP.NGO,
            CON_GIAP.MUI
        ], 
        listCanChi: [
            "GIAP_THAN",
            "AT_DAU",
            "BINH_TUAT",
            "DINH_HOI",
            "MAU_TY",
            "KY_SUU",
            "CANH_DAN",
            "TAN_MAO",
            "NHAM_THIN",
            "QUY_TY"
        ]
    },
    {
        listConGiap: [
            CON_GIAP.THIN,
            CON_GIAP.TYJ
        ], 
        listCanChi: [
            "GIAP_NGO",
            "AT_MUI",
            "BINH_THAN",
            "DINH_DAU",
            "MAU_TUAT",
            "KY_HOI",
            "CANH_TY",
            "TAN_SUU",
            "NHAM_DAN",
            "QUY_MAO"
        ]
    },
    {
        listConGiap: [
            CON_GIAP.DAN,
            CON_GIAP.MAO
        ], 
        listCanChi: [
            "GIAP_THIN",
            "AT_TY",
            "BINH_NGO",
            "DINH_MUI",
            "MAU_THAN",
            "KY_DAU",
            "CANH_TUAT",
            "TAN_HOI",
            "NHAM_TY",
            "QUY_SUU"
        ]
    },
    {
        listConGiap: [
            CON_GIAP.TYS,
            CON_GIAP.SUU
        ], 
        listCanChi: [
            "GIAP_DAN",
            "AT_MAO",
            "BINH_THIN",
            "DINH_TY",
            "MAU_NGO",
            "KY_MUI",
            "CANH_THAN",
            "TAN_DAU",
            "NHAM_TUAT",
            "QUY_HOI"
        ]
    },
];

export default ListKhongVong