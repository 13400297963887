import React from "react";
import { GoogleLogout } from 'react-google-login';

const Logout = (props) => {
    const logout = () => {
        localStorage.removeItem("profile")
        props.getProfile({})
    }

    return (
        <div className="dropdown-content">
            <GoogleLogout
                clientId={process.env.REACT_APP_GOOGLE_ID}
                buttonText="Đăng xuất"
                onLogoutSuccess={logout}
                className="google-logout"
            >
            </GoogleLogout>
        </div>
    )
}

export default Logout