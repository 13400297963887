import React, { useState } from "react";
import logo from "../assets/logo.png";
import img1 from "../assets/CHUONGTRINHGIANGDAY-01.png";
import img2 from "../assets/CHUONGTRINHGIANGDAY-02.png";
import img3 from "../assets/CHUONGTRINHGIANGDAY-03.png";
import img4 from "../assets/CHUONGTRINHGIANGDAY-04.png";
import img5 from "../assets/CHUONGTRINHGIANGDAY-05.png";
import img6 from "../assets/CHUONGTRINHGIANGDAY-06.png";
import img7 from "../assets/CHUONGTRINHGIANGDAY-07.png";
import img8 from "../assets/CHUONGTRINHGIANGDAY-08.png";
import img9 from "../assets/CHUONGTRINHGIANGDAY-09.png";
import img10 from "../assets/CHUONGTRINHGIANGDAY-10.png";
import img11 from "../assets/CHUONGTRINHGIANGDAY-11.png";

const DefaultHeader = () => {
    let arrImg = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11]

    const renderHeaderImg = () => {
        return arrImg.map(item => {
            return (
                <img src={item} className="header-img ml-1" />
            )
        })
    }

    const onPrint = () => {
        window.print()
    }

    return (
        <div className="header d-flex justify-content-between">
            <div className="d-flex">
                <img src={logo} className="header-logo" />
                <div className="ml-3">
                    <p className="header-text-blue text-uppercase mb-1">TUONG MINH FENG SHUI</p>
                    <p className="header-text-orange text-uppercase mb-0">KINH DỊCH VĂN VƯƠNG LỤC HÀO</p>
                </div>
            </div>
            <div className="d-flex">
                {renderHeaderImg()}
                <div className="header-dev-icon-print" onClick={onPrint}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-printer-fill" viewBox="0 0 16 16">
                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    </svg>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                    </svg> */}
                </div>
            </div>
        </div>
    )
}

export default DefaultHeader