import { faFacebook, faGooglePlus, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-mini';
import React from 'react';
import Logofooter from '../../src/assets/logo-footer.jpg';
import '../App.css';

class DefaultFooter extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="div-footer">
                <div className="d-flex justify-content-between">
                    <div className="ads-show">
                        <a href="/H%E1%BB%8Dc%20phong%20thu%E1%BB%B7%20%E1%BB%9F%20%C4%91%C3%A2u%20t%E1%BB%91t" target="_self">
                            <img typeof="foaf:Image" src={Logofooter} alt="" />
                        </a>
                    </div>
                    <div>
                        <p className="mb-0">Địa chỉ: Lầu 4 &amp; 6 - Smart Building - WinHome - 793/49/1 Trần Xuân Soạn, Tân Hưng, Quận 7, TP. Hồ Chí Minh</p>
                        <p className="mb-0">Điện thoại: (+84) 28 66814141 - &nbsp;Mobile: (+84)&nbsp;0981 229 461</p>
                        <p className="mb-0">Email: info@tuongminhfengshui.com&nbsp;</p>
                    </div>
                </div>
                <div>
                    <div className="row justify-content-end social">
                        <a className="facebook-link" href="https://business.facebook.com/hocvienphongthuytuongminh/">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a className="twitter-link" href="https://www.facebook.com/phongthuytuongminh/">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a className="google-link" href="https://www.facebook.com/phongthuytuongminh/">
                            <FontAwesomeIcon icon={faGooglePlus} />
                        </a>
                        <a className="youtube-link" href="https://www.youtube.com/channel/UCxVMAH7gUiM9-zszIDKj9uA">
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                    </div>
                    <div className="footer-bottom">
                        <div className="menu-footer-b">
                            <ul className="menu clearfix">
                                <li className="first expanded">
                                    <a href="http://phongthuytuongminh.com/gioi-thieu-ve-tuong-minh-phong-thuy.html" title="">
                                        Về chúng tôi
                                    </a>
                                    <ul className="menu clearfix">
                                        <li className="first leaf">
                                            <a href="http://phongthuytuongminh.com/gioi-thieu-ve-tuong-minh-phong-thuy.html" title="">
                                                Về Tường Minh phong thuỷ
                                            </a>
                                        </li>
                                        <li className="leaf">
                                            <a href="http://phongthuytuongminh.com/master-phuong-nguyen.html" title="">
                                                Thầy Nguyễn Thành Phương
                                            </a>
                                        </li>
                                        <li className="last leaf">
                                            <a href="http://phongthuytuongminh.com/thu-vien-anh.html" title="">
                                                Thư viện ảnh
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="expanded">
                                    <a href="http://phongthuytuongminh.com/tu-van-phong-thuy.html" title="">
                                        Dịch Vụ Tư vấn
                                    </a>
                                    <ul className="menu clearfix">
                                        <li className="first leaf">
                                            <a href="http://phongthuytuongminh.com/phong-thuy-nha-o.html" title="">
                                                Phong thủy Nhà ở
                                            </a>
                                        </li>
                                        <li className="leaf">
                                            <a href="http://phongthuytuongminh.com/chon-ngay-tot-nhat-trach.html-0" title="">
                                                Chọn ngày tốt (Trạch Nhật)
                                            </a>
                                        </li>
                                        <li className="last leaf">
                                            <a href="http://phongthuytuongminh.com/phong-thuy-mo-ma-am-trach.html" title="">
                                                Mộ Phần (Âm trạch)
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="expanded">
                                    <a href="http://phongthuytuongminh.com/hoc-phong-thuy.html" title="">
                                        Học phong thủy
                                    </a>
                                    <ul className="menu clearfix">
                                        <li className="first leaf">
                                            <a href="http://phongthuytuongminh.com/phong-thuy-dia-ly.html" title="">
                                                Phong thủy địa lý
                                            </a>
                                        </li>
                                        <li className="leaf">
                                            <a href="http://phongthuytuongminh.com/bat-tu-tu-vi.html" title="">
                                                Bát tự tử vi
                                            </a>
                                        </li>
                                        <li className="leaf">
                                            <a href="/" title="" className="active">
                                                Trạch Nhật (Chọn Ngày Giờ)
                                            </a>
                                        </li>
                                        <li className="last leaf">
                                            <a href="/lich-hoc-phong-thuy" title="">
                                                Lịch học phong thủy
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="expanded">
                                    <a href="http://phongthuytuongminh.com/kien-thuc-phong-thuy.html-0&gt;" title="">
                                        Kiến thức phong thủy
                                    </a>
                                    <ul className="menu clearfix">
                                        <li className="first leaf">
                                            <a href="http://phongthuytuongminh.com/tai-lieu-phong-thuy.html" title="">
                                                Tài liệu phong thủy
                                            </a>
                                        </li>
                                        <li className="last leaf">
                                            <a href="http://phongthuytuongminh.com/bai-viet-phong-thuy.html-1" title="">
                                                Bài viết phong thủy
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="last leaf">
                                    <a href="/contact" title="Liên hệ">
                                        Liên hệ
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p>by Phong Thuỷ Tường Minh - Hocphongthuy.net {moment().year()} Allright reserved</p>
            </div>
        );
    }
}

export default DefaultFooter;
