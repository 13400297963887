import React, { useEffect, useState } from "react";

const KetLuanPhanTich = (props) => {
    const [paddingY, setPaddingY] = useState(0);

    useEffect(() => {
        var x = props?.queKinhDich?.vieccanhoi;
        document.getElementById("innerTextOutput").innerHTML = x;
        let height = document.getElementById("innerTextOutput") && document.getElementById("innerTextOutput").offsetHeight;

        setPaddingY((height - 125) / 2)
    }, [props?.queKinhDich])

    return (
        <div className="div-ket-luan-phan-tich">
            <div className="row mb-5">
                <div className="col-2">
                    <strong>Kết luận:</strong>
                </div>
                <div className="col-10">
                    <textarea placeholder="Kết luận" rows="5" className="w-100 textarea-ket-luan" />
                </div>
            </div>
            <div className="d-flex">
                <div className="div-phan-tich-ky-thuat-left text-center" style={{ padding: `${paddingY}px 10px` }}>
                    <svg className="mb-1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                    </svg>
                    <strong>Phân Tích Kỹ Thuật</strong>
                </div>
                <div id="innerTextOutput" className="div-phan-tich-ky-thuat-right w-100 text-left" />
            </div>
        </div>
    )
}

export default KetLuanPhanTich